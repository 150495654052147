<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import enquireScreen from "@/utils/device";

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    let that = this;
    enquireScreen((deviceType) => {
      // tablet
      if (deviceType === 0) {
        that.$store.commit("TOGGLE_DEVICE", "mobile");
        that.$store.dispatch("setSidebar", false);
      }
      // mobile
      else if (deviceType === 1) {
        that.$store.commit("TOGGLE_DEVICE", "mobile");
        that.$store.dispatch("setSidebar", false);
      } else {
        that.$store.commit("TOGGLE_DEVICE", "desktop");
        that.$store.dispatch("setSidebar", true);
      }
    });
  },
};
</script>
<style lang="less" >
@import url("//at.alicdn.com/t/font_2257821_ke870y087ul.css");
</style>
<style>
#app {
  height: 100%;
}
</style>
