import * as api from '@/api/api'
import { isURL } from '@/utils/validate'
// import onlineCommons from '@jeecg/antd-online-mini'
export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : (hour <= 11 ? '上午好' : (hour <= 13 ? '中午好' : (hour < 20 ? '下午好' : '晚上好')))
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  let index = Math.floor((Math.random() * arr.length))
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  let event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

/**
 *  校验自定义组件多语言表格所有语言是否都填写值
 * @param rule 校验规则对象
 * @param value value langtable值
 * @param validateCallback 校验回调 必须validateCallback()
 * @returns {}
 */
export function validateLang(rule, value, validateCallback, languageList) {
  console.log('jiaoyan')
  let flag = false
  let msg = ''
  for (let langIndex = value.length; langIndex--;) {
    let langItem = value[langIndex]
    for (let i in langItem) {
      if (i === 'UUID') {
        continue
      }
      if (!langItem[i].initialValue) {
        flag = true
        let langTarget = languageList.find((item) => item.code === i)
        if (langTarget) {
          msg = langTarget.name
        }
        break
      }
    }
  }
  if (flag) {
    validateCallback(`${msg || '多语言'}文本信息填写不完整！`)
  } else {
    validateCallback()
  }
}

/**
 *  根据functionParm 和stylejson 回填value
 * @param obj {}
 * @returns {}
 */
export function RulesParmToValue(obj) {
  if (obj.functionParm) {
    let functionParm = obj.functionParm && JSON.parse(obj.functionParm)
    let styleJson = JSON.parse(obj.styleJson)
    if (functionParm && Object.keys(functionParm).length) {
      if (obj.controlType.indexOf('range') > -1) {
        obj.ruleValue = functionParm[styleJson[0].key]
        obj.ruleValueEnd = functionParm[styleJson[1].key]
      } else if (obj.controlType === 'checkbox' || obj.controlType === 'select') {
        let keys = Object.keys(functionParm)
        obj.ruleValue = keys
        if (keys.indexOf('cerType') > -1) {
          obj.cerType = functionParm.cerType.split(',')
        }
      } else if (obj.controlType === 'input' || obj.controlType === 'switch') {
        obj.ruleValue = functionParm[styleJson[0].key]
      } else if (obj.controlType === 'radio') {
        obj.ruleValue = Object.keys(functionParm)[0]
      }
    }
    return obj
  }
}

/**
 *  根据规则值和styleJson生成functionParm
 */
export function formatRules(rulesList) {
  let arr = []
  rulesList.forEach((item) => {
    // let obj = pick(item, 'ruleType', 'ruleFunction', 'controlType', 'styleJson', 'smusRuleId', 'name')
    let styleJson = JSON.parse(item.styleJson)
    item.functionParm = {}
    if (item.ruleValue) {
      if (item.controlType === 'checkbox' || item.controlType === 'select') {
        item.ruleValue.forEach((value) => {
          if (value !== 'cerType') {
            item.functionParm[value] = true
          } else {
            item.functionParm[value] = item.cerType && item.cerType.join() || 'idcard,hkmtpassport,passport'
            item.functionParm['cerNo'] = true
          }
        })
      } else if (item.controlType === 'numberrange' || item.controlType === 'daterange' || item.controlType === 'timerange') {
        styleJson.forEach((i, index) => {
          if (index === 0) {
            item.functionParm[i.key] = item.ruleValue
          } else if (index === 1) {
            item.functionParm[i.key] = item.ruleValueEnd
          }
        })
      } else if (item.controlType === 'input' || item.controlType === 'switch') {
        item.functionParm[styleJson[0].key] = item.ruleValue
      } else if (item.controlType === 'radio') {
        item.functionParm[styleJson[0].key] = true
      }
    }
    item.functionParm = JSON.stringify(item.functionParm)
    if (!item.ruleName) {
      item.ruleName = item.name
    }
    arr.push(item)
  })
  return arr
}
/**
 * 触发 实现深拷贝函数
 */
export function deepClone(data) {
  const type = judgeType(data)
  let obj = null
  if (type === 'array') {
    obj = []
    for (let i = 0; i < data.length; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    obj = {}
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        obj[key] = deepClone(data[key])
      }
    }
  } else {
    return data
  }
  return obj
}
export function isEmpty(obj){
  if(typeof obj == "undefined" || obj == null || obj == "" || obj=='null'){
      return true;
  }else{
      return false;
  }
}
let isletter = /^[a-zA-Z]+$/;
export function getRuleLableNames(functionParm,styleJson){
  if(isEmpty(functionParm)){
      return '';
  }
  if(typeof styleJson == "string"){
    styleJson = JSON.parse(styleJson);
  }
  let ve  = functionParm.split(",")
  let labelVe = '';
  for(let v in ve){
      let ky = ve[v]
      if(labelVe != ''){
        labelVe = labelVe + ","    
      }
      if(isletter.test(ky.trim())){
        labelVe = labelVe + analysisRuleAndChildStyle(ky,styleJson);
      }else{
        labelVe = labelVe + ky;
      }
  }
  return labelVe
}
 // 解析规则 返回规则得中文名称 含管理规则
function analysisRuleAndChildStyle(key,styleJson){

  let result = '';
  for(let js in styleJson){
      if(styleJson[js].key == key){
          result =  styleJson[js].label 
          let link = styleJson[js].link
          if(link){
            let ve  = ''
            let linkValue = link[0].value.toString()
            if(linkValue){
              console.log(linkValue)
               if(!isletter.test(linkValue)){
                  result = result+ "( " + linkValue +" )"
                  return result;
               }
               ve = linkValue.split(",")
            }else{
               ve  =  link[0].valueArray
            }
            let styjs = link[0].styleJson
            let res = '';
            for(let v in ve){
              if(res !=''){
                  res = res+','
              }
              res = res + analysisRuleAndChildStyle(ve[v],styjs);
            }
            result = result+ "( " + res +" )"
          }
      }
  }
  return result;
}

export function judgeType(obj) {
  // tostring会返回对应不同的标签的构造函数
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 计算笛卡尔积
 * @param arr [[a1,a2],[b1,b2]]
 * @returns []
 */
export function calcDescartes(array) {
  if (array.length < 2) return array[0] || []
  return [].reduce.call(array, function (col, set) {
    var res = []
    col.forEach(function (c) {
      set.forEach(function (s) {
        var t = [].concat(Array.isArray(c) ? c : [c])
        t.push(s)
        res.push(t)
      })
    })
    return res
  })
}
/**
 * 格式化一个多语言对象，返回多语言表格使用对象
 * @param data 目标对象
 * @param key 多语言字段
 * @returns []
 */
export function formatLanguageData(data, key) {
  //

  if (data instanceof Array) {
    let arr = []
    data.forEach(item => {
      let obj = { UUID: randomUUID() }
      obj.zh = {
        id: item.id,
        initialValue: item[key] || '',
        initialKey: key
      }
      for (let i in item.languageData) {
        obj[i] = {
          id: item.languageData[i].id,
          initialValue: item.languageData[i][key] || '',
          initialKey: key
        }
      }
      arr.push(obj)
    })
    return arr
  } else {
    let arr = []
    let obj = { UUID: randomUUID() }
    obj.zh = {
      id: data.id,
      initialValue: data[key] || '',
      initialKey: key
    }
    for (let i in data.languageData) {
      obj[i] = {
        id: data.languageData[i].id,
        initialValue: data.languageData[i][key] || '',
        initialKey: key
      }
    }
    arr.push(obj)
    return arr
  }
}
/**
 * 判断两个数组是否相等
 * @param arr [[a1,a2],[b1,b2]]
 * @returns []
 */
export function equalArray(arr1, arr2) {
  let rule1 = arr1.length === arr2.length
  let rule2 = arr1.every(a => arr2.some(b => a === b))
  let rule3 = arr2.every(_b => arr1.some(_a => _a === _b))
  return rule1 && rule2 && rule3
}
/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj) {
  if (!(typeof obj === 'object')) {
    return
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key) &&
      (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
      delete obj[key]
    }
  }
  return obj
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate(value, fmt) {
  let regPos = /^\d+(\.\d+)?$/
  if (regPos.test(value)) {
    // 如果是数字
    let getDate = new Date(value)
    let o = {
      'M+': getDate.getMonth() + 1,
      'd+': getDate.getDate(),
      'h+': getDate.getHours(),
      'm+': getDate.getMinutes(),
      's+': getDate.getSeconds(),
      'q+': Math.floor((getDate.getMonth() + 3) / 3),
      'S': getDate.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  } else {
    // TODO
    value = value.trim()
    return value.substr(0, fmt.length)
  }
}

// 生成首页路由
export function generateIndexRouter(data) {
  let indexRouter = [{
    path: '/',
    name: 'dashboard',
    // component: () => import('@/components/layouts/BasicLayout'),
    component: resolve => require(['@/components/layouts/TabLayout'], resolve),
    meta: { title: '首页' },
    redirect: '/dashboard/analysis',
    children: [
      ...generateChildRouters(data)
    ]
  }, {
    'path': '*', 'redirect': '/404', 'hidden': true
  }]
  return indexRouter
}

// 生成嵌套路由（子路由）

function generateChildRouters(data) {
  const routers = []
  for (let item of data) {
    let component = ''
    if (item.component.indexOf('layouts') >= 0) {
      component = 'components/' + item.component
    } else {
      component = 'views/' + item.component
    }

    // eslint-disable-next-line
    let URL = (item.meta.url || '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
    if (isURL(URL)) {
      item.meta.url = URL
    }

    let componentPath
    if (item.component == 'modules/online/cgform/OnlCgformHeadList') {
    } else if (item.component == 'modules/online/cgform/OnlCgformCopyList') {
    } else if (item.component == 'modules/online/cgform/auto/OnlCgformAutoList') {
    } else if (item.component == 'modules/online/cgform/auto/OnlCgformTreeList') {
    } else if (item.component == 'modules/online/cgform/auto/erp/OnlCgformErpList') {
    } else if (item.component == 'modules/online/cgform/auto/innerTable/OnlCgformInnerTableList') {
    } else if (item.component == 'modules/online/cgreport/OnlCgreportHeadList') {
    } else if (item.component == 'modules/online/cgreport/auto/OnlCgreportAutoList') {
    } else {
      componentPath = resolve => require(['@/' + component + '.vue'], resolve)
    }

    let menu = {
      path: item.path,
      name: item.name,
      redirect: item.redirect,
      component: componentPath,
      hidden: item.hidden,
      // component:()=> import(`@/views/${item.component}.vue`),
      meta: {
        title: item.meta.title,
        icon: item.meta.icon,
        url: item.meta.url,
        permissionList: item.meta.permissionList,
        keepAlive: item.meta.keepAlive,
        param:item.meta.param,
        /* update_begin author:wuxianquan date:20190908 for:赋值 */
        internalOrExternal: item.meta.internalOrExternal
        /* update_end author:wuxianquan date:20190908 for:赋值 */
      }
    }
    if (item.alwaysShow) {
      menu.alwaysShow = true
      menu.redirect = menu.path
    }
    if (item.children && item.children.length > 0) {
      menu.children = [...generateChildRouters(item.children)]
    }
    // --update-begin----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
    // 判断是否生成路由
    if (item.route && item.route === '0') {
    } else {
      routers.push(menu)
    }
    // --update-end----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
  }
  return routers
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  if (arguments.length === 1) {
    let [length] = arguments
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)))
    return parseInt(nums.join(''))
  } else if (arguments.length >= 2) {
    let [min, max] = arguments
    return random(min, max)
  } else {
    return Number.NaN
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1
  if (!chats) chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
  let str = ''
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1)
    str += chats[num]
  }
  return str
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID() {
  let chats = '0123456789abcdef'
  return randomString(32, chats)
}

/**
 * 下划线转驼峰
 * @param string
 * @returns {*}
 */
export function underLine2CamelCase(string) {
  return string.replace(/_([a-z])/g, function (all, letter) {
    return letter.toUpperCase()
  })
}

/**
 * 判断是否显示办理按钮
 * @param bpmStatus
 * @returns {*}
 */
export function showDealBtn(bpmStatus) {
  if (bpmStatus != '1' && bpmStatus != '3' && bpmStatus != '4') {
    return true
  }
  return false
}

/**
 * 增强CSS，可以在页面上输出全局css
 * @param css 要增强的css
 * @param id style标签的id，可以用来清除旧样式
 */
export function cssExpand(css, id) {
  let style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = `@charset "UTF-8"; ${css}`
  // 清除旧样式
  if (id) {
    let $style = document.getElementById(id)
    if ($style != null) $style.outerHTML = ''
    style.id = id
  }
  // 应用新样式
  document.head.appendChild(style)
}

/** 用于js增强事件，运行JS代码，可以传参 */
// options 所需参数：
//    参数名         类型            说明
//    vm             VueComponent    vue实例
//    event          Object          event对象
//    jsCode         String          待执行的js代码
//    errorMessage   String          执行出错后的提示（控制台）
export function jsExpand(options = {}) {
  // 绑定到window上的keyName
  let windowKeyName = 'J_CLICK_EVENT_OPTIONS'
  if (typeof window[windowKeyName] !== 'object') {
    window[windowKeyName] = {}
  }

  // 随机生成JS增强的执行id，防止冲突
  let id = randomString(16, 'qwertyuioplkjhgfdsazxcvbnm'.toUpperCase())
  // 封装按钮点击事件
  let code = `
    (function (o_${id}) {
      try {
        (function (globalEvent, vm) {
          ${options.jsCode}
        })(o_${id}.event, o_${id}.vm)
      } catch (e) {
        o_${id}.error(e)
      }
      o_${id}.done()
    })(window['${windowKeyName}']['EVENT_${id}'])
  `
  // 创建script标签
  const script = document.createElement('script')
  // 将需要传递的参数挂载到window对象上
  window[windowKeyName]['EVENT_' + id] = {
    vm: options.vm,
    event: options.event,
    // 当执行完成时，无论如何都会调用的回调事件
    done() {
      // 执行完后删除新增的 script 标签不会撤销执行结果（已产生的结果不会被撤销）
      script.outerHTML = ''
      delete window[windowKeyName]['EVENT_' + id]
    },
    // 当js运行出错的时候调用的事件
    error(e) {
    }
  }
  // 将事件挂载到document中
  script.innerHTML = code
  document.body.appendChild(script)
}

/**
 * 重复值验证工具方法
 *
 * 使用示例：
 * { validator: (rule, value, callback) => validateDuplicateValue('sys_fill_rule', 'rule_code', value, this.model.id, callback) }
 *
 * @param tableName 被验证的表名
 * @param fieldName 被验证的字段名
 * @param fieldVal 被验证的值
 * @param dataId 数据ID，可空
 * @param callback
 */
export function validateDuplicateValue(tableName, fieldName, fieldVal, dataId, callback) {
  if (fieldVal) {
    let params = { tableName, fieldName, fieldVal, dataId }
    api.duplicateCheck(params).then(res => {
      res['success'] ? callback() : callback(res['message'])
    }).catch(err => {
      callback(err.message || err)
    })
  } else {
    callback()
  }
}

/**
 * 根据编码校验规则code，校验传入的值是否合法
 *
 * 使用示例：
 * { validator: (rule, value, callback) => validateCheckRule('common', value, callback) }
 *
 * @param ruleCode 编码校验规则 code
 * @param value 被验证的值
 * @param callback
 */
export function validateCheckRule(ruleCode, value, callback) {
  if (ruleCode && value) {
    value = encodeURIComponent(value)
    api.checkRuleByCode({ ruleCode, value }).then(res => {
      res['success'] ? callback() : callback(res['message'])
    }).catch(err => {
      callback(err.message || err)
    })
  } else {
    callback()
  }
}

/**
 * 如果值不存在就 push 进数组，反之不处理
 * @param array 要操作的数据
 * @param value 要添加的值
 * @param key 可空，如果比较的是对象，可能存在地址不一样但值实际上是一样的情况，可以传此字段判断对象中唯一的字段，例如 id。不传则直接比较实际值
 * @returns {boolean} 成功 push 返回 true，不处理返回 false
 */
export function pushIfNotExist(array, value, key) {
  for (let item of array) {
    if (key && (item[key] === value[key])) {
      return false
    } else if (item === value) {
      return false
    }
  }
  array.push(value)
  return true
}

/**
 * 可用于判断是否成功
 * @type {symbol}
 */
export const succeedSymbol = Symbol()
/**
 * 可用于判断是否失败
 * @type {symbol}
 */
export const failedSymbol = Symbol()

/**
 * 使 promise 无论如何都会 resolve，除非传入的参数不是一个Promise对象或返回Promise对象的方法
 * 一般用在 Promise.all 中
 *
 * @param promise 可传Promise对象或返回Promise对象的方法
 * @returns {Promise<any>}
 */
export function alwaysResolve(promise) {
  return new Promise((resolve, reject) => {
    let p = promise
    if (typeof promise === 'function') {
      p = promise()
    }
    if (p instanceof Promise) {
      p.then(data => {
        resolve({ type: succeedSymbol, data })
      }).catch(error => {
        resolve({ type: failedSymbol, error })
      })
    } else {
      reject('alwaysResolve: 传入的参数不是一个Promise对象或返回Promise对象的方法')
    }
  })
}

/**
 * 简单实现防抖方法
 *
 * 防抖(debounce)函数在第一次触发给定的函数时，不立即执行函数，而是给出一个期限值(delay)，比如100ms。
 * 如果100ms内再次执行函数，就重新开始计时，直到计时结束后再真正执行函数。
 * 这样做的好处是如果短时间内大量触发同一事件，只会执行一次函数。
 *
 * @param fn 要防抖的函数
 * @param delay 防抖的毫秒数
 * @returns {Function}
 */
export function simpleDebounce(fn, delay = 100) {
  let timer = null
  return function () {
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(null, args)
    }, delay)
  }
}

/**
 * 不用正则的方式替换所有值
 * @param text 被替换的字符串
 * @param checker  替换前的内容
 * @param replacer 替换后的内容
 * @returns {String} 替换后的字符串
 */
export function replaceAll(text, checker, replacer) {
  let lastText = text
  text = text.replace(checker, replacer)
  if (lastText !== text) {
    return replaceAll(text, checker, replacer)
  }
  return text
}

/**
 * 获取事件冒泡路径，兼容 IE11，Edge，Chrome，Firefox，Safari
 * 目前使用的地方：JEditableTable Span模式
 */
export function getEventPath(event) {
  let target = event.target
  let path = (event.composedPath && event.composedPath()) || event.path

  if (path != null) {
    return (path.indexOf(window) < 0) ? path.concat(window) : path
  }

  if (target === window) {
    return [window]
  }

  let getParents = (node, memo) => {
    memo = memo || []
    const parentNode = node.parentNode

    if (!parentNode) {
      return memo
    } else {
      return getParents(parentNode, memo.concat(parentNode))
    }
  }
  return [target].concat(getParents(target), window)
}

/**
 * 根据组件名获取父级
 * @param vm
 * @param name
 * @returns {Vue | null|null|Vue}
 */
export function getVmParentByName(vm, name) {
  let parent = vm.$parent
  if (parent && parent.$options) {
    if (parent.$options.name === name) {
      return parent
    } else {
      let res = getVmParentByName(parent, name)
      if (res) {
        return res
      }
    }
  }
  return null
}

/**
 * 汉字转语音
 */
export function speakCharacter(info){
  let ssu = new window.SpeechSynthesisUtterance(info);
  window.speechSynthesis.speak(ssu);
}


/**
 * 汉字转语音
 */
export function speechRecogn(){
   // 创建一个实例
   let recognition = new webkitSpeechRecognition();
   recognition.lang = 'zh-CN'; // 识别中文
   recognition.interimResults = false; // 停止语音后是否立即返回最终结果
   // 开启语音识别
   recognition.start();
   return recognition;
   
}