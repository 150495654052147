// import { UserLayout, TabLayout, RouteView, BlankLayout, PageView } from '@/components/layouts'
import { UserLayout, TabLayout } from '@/components/layouts'

/**
 * 走菜单，走权限控制
 * @type {[null,null]}
 */
export const asyncRouterMap = [

    {
        path: '/',
        name: 'dashboard',
        component: TabLayout,
        meta: { title: '首页' },
        redirect: '/dashboard/analysis',
        children: []
    },
    {
        path: '*', redirect: '/404', hidden: true
    }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
    {
        path: '/user',
        component: UserLayout,
        redirect: '/user/login',
        hidden: true,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "user" */ '@/views/login/Login')
            },
            // {
            //     path: 'register',
            //     name: 'register',
            //     component: () => import(/* webpackChunkName: "user" */ '@/views/user/register/Register')
            // },
            // {
            //     path: 'register-result',
            //     name: 'registerResult',
            //     component: () => import(/* webpackChunkName: "user" */ '@/views/user/register/RegisterResult')
            // },
            // {
            //     path: 'alteration',
            //     name: 'alteration',
            //     component: () => import(/* webpackChunkName: "user" */ '@/views/user/alteration/Alteration')
            // },
        ]
    },

    // {
    //   path: '/',
    //   name: 'index',
    //   component: TabLayout,
    //   meta: {title: '首页'},
    //   redirect: '/dashboard/workplace',
    //   children: [
    //     {
    //       path: '/online',
    //       name: 'online',
    //       redirect: '/online',
    //       component: RouteView,
    //       meta: {title: '在线开发', icon: 'dashboard', permission: ['dashboard']},
    //       children: [
    //         {
    //           path: '/online/auto/:code',
    //           name: 'report',
    //           component: () => import('@/views/modules/online/cgreport/OnlCgreportAutoList')
    //         },
    //       ]
    //     },
    //   ]
    // },

    // {
    //     path: '/test',
    //     component: BlankLayout,
    //     redirect: '/test/home',
    //     children: [
    //         {
    //             path: 'home',
    //             name: 'TestHome',
    //             component: () => import('@/views/Home')
    //         }
    //     ]
    // },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
    },

]
