import Vue from 'vue'
import { login, logout, phoneLogin, thirdLogin } from "@/api/login"
import { ACCESS_TOKEN, USER_NAME, USER_INFO, TENANT_ID, SYS_BUTTON_AUTH, UI_CACHE_DB_DICT_DATA, CACHE_INCLUDED_ROUTES } from "@/store/mutation-types"
import { welcome } from "@/utils/util"
import { queryPermissionsByUser } from '@/api/api'
import { getAction } from '@/api/manage'

const user = {
  state: {
    token: '',
    username: '',
    realname: '',
    tenantid: '',
    welcome: '',
    avatar: '',
    permissionList: [],
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username
      state.realname = realname
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
      state.permissionList = permissionList
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_TENANT: (state, id) => {
      state.tenantid = id
    },
  },

  actions: {

    // 登录
    Login({ commit, dispatch }, userInfo) {
      return new Promise(async (resolve, reject) => {
        login(userInfo).then(response => {
          if (response.code == '200') {
            const { access_token } = response.data
            // const userInfo = result.userInfo
            Vue.ls.set(ACCESS_TOKEN, access_token, 7 * 24 * 60 * 60 * 1000)
            // Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            // Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            // Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', access_token)
            // dispatch('GetPermissionList').then(() => {
            resolve(response)
            // })

            // commit('SET_INFO', userInfo)
            // commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname, welcome: welcome() })
            // commit('SET_AVATAR', userInfo.avatar)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetPermissionList({ commit }) {
      return new Promise((resolve, reject) => {
        queryPermissionsByUser().then(response => {
          const { departName, menus, realName, tenantId,userId,roleCode } = response.data
          const userInfo = {
            realName,
            tenantId,
            departName,
            username: realName,
            userId,
            roleCode
          }

          Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(TENANT_ID, tenantId, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
          commit('SET_INFO', userInfo)
          commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname, welcome: welcome() })
          // const menuData = response.result.menu;
          // const authData = response.result.auth;
          // const allAuthData = response.result.allAuth;
          // //Vue.ls.set(USER_AUTH,authData);
          // sessionStorage.setItem(USER_AUTH, JSON.stringify(authData));
          // sessionStorage.setItem(SYS_BUTTON_AUTH, JSON.stringify(allAuthData));
          if (menus && menus.length > 0) {
            //update--begin--autor:qinfeng-----date:20200109------for：JEECG-63 一级菜单的子菜单全部是隐藏路由，则一级菜单不显示------
            menus.forEach(item => {
              if (item["children"]) {
                let hasChildrenMenu = item["children"].filter((i) => {
                  return !i.hidden || i.hidden == false
                })
                if (hasChildrenMenu == null || hasChildrenMenu.length == 0) {
                  item["hidden"] = true
                }
              }
            })
            //console.log(" menu show json ", menuData)
            //update--end--autor:qinfeng-----date:20200109------for：JEECG-63 一级菜单的子菜单全部是隐藏路由，则一级菜单不显示------
            commit('SET_PERMISSIONLIST', menus)
          } else {
            reject('getPermissionList: permissions must be a non-null array !')
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        let logoutToken = state.token;
        commit('SET_TOKEN', '')
        commit('SET_PERMISSIONLIST', [])
        Vue.ls.remove(ACCESS_TOKEN)
        Vue.ls.remove(USER_INFO)
        Vue.ls.remove(USER_NAME)
        Vue.ls.remove(UI_CACHE_DB_DICT_DATA)
        Vue.ls.remove(CACHE_INCLUDED_ROUTES)
        //console.log('logoutToken: '+ logoutToken)
        logout(logoutToken).then(() => {
          if (process.env.VUE_APP_SSO == 'true') {
            let sevice = 'http://' + window.location.host + '/'
            let serviceUrl = encodeURIComponent(sevice)
            window.location.href = process.env.VUE_APP_CAS_BASE_URL + '/logout?service=' + serviceUrl
          }
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },

    saveTenant({ commit }, id) {
      Vue.ls.set(TENANT_ID, id, 7 * 24 * 60 * 60 * 1000)
      commit('SET_TENANT', id)
    }


  }
}

export default user