import Vue from 'vue'
import { axios } from '@/utils/request'
import qs from 'qs'

const api = {
  user: '/api/user',
  role: '/api/role',
  service: '/api/service',
  permission: '/api/permission',
  permissionNoPager: '/api/permission/no-pager'
}

export default api

// formData
export function formAction(url, parameter) {
  return axios({
    url: url,
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    }
  })
}
// formData
export function formAction2(url, parameter) {
  return axios({
    url: url,
    method: 'post',
    data: parameter,
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    }
  })
}
// formData 富文本编辑用到的表单传值
export function formActionMulti(url, parameter) {
  return axios({
    url: url,
    method: 'post',
    data: parameter,

  })
}
// post
export function _postAction(url, parameter) {
  return axios({
    url: url,
    method: 'post',
    data: parameter,

  })
}

// post
export function postAction(url, parameter) {
  return axios({
    url: url,
    method: 'post',
    data: parameter,

  })
}

// post method= {post | put}
export function httpAction(url, parameter, method) {
  return axios({
    url: url,
    method: method,
    data: parameter,

  })
}

// put
export function putAction(url, parameter) {
  return axios({
    url: url,
    method: 'put',
    data: parameter,

  })
}

// get
export function getAction(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter,

  })
}

// deleteAction
export function deleteAction(url, data) {
  return axios({
    url: url,
    method: 'post',
    data,
  })
}

export function getUserList(parameter) {
  return axios({
    url: api.user,
    method: 'get',
    params: parameter,

  })
}

export function getRoleList(parameter) {
  return axios({
    url: api.role,
    method: 'get',
    params: parameter,

  })
}

export function getServiceList(parameter) {
  return axios({
    url: api.service,
    method: 'get',
    params: parameter,

  })
}

export function getPermissions(parameter) {
  return axios({
    url: api.permissionNoPager,
    method: 'get',
    params: parameter,

  })
}

// id == 0 add     post
// id != 0 update  put
export function saveService(parameter) {
  return axios({
    url: api.service,
    method: parameter.id == 0 ? 'post' : 'put',
    data: parameter,

  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return axios({
    url: url,
    params: parameter,
    method: 'get',
    responseType: 'blob',

  })
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, fileName, parameter) {
  return downFile(url, parameter).then((data) => {
    if (!data || data.size === 0) {
      Vue.prototype['$message'].warning('文件下载失败')
      return
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      let url = window.URL.createObjectURL(new Blob([data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // 下载完成移除元素
      window.URL.revokeObjectURL(url) // 释放掉blob对象
    }
  })
}

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
export function uploadAction(url, parameter) {
  return axios({
    url: url,
    data: parameter,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data', // 文件上传
    }
  })
}


export function uploadBlobAction(url, parameter) {
  return axios({
    url: url,
    responseType: 'blob',
    data: parameter,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data', // 文件上传
    }
  })
}
export function toDownExcel(data,fileName) {
  const link = document.createElement('a')
  let blob = new Blob([data], {type: 'application/vnd.ms-excel'})
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  link.download = fileName //下载的文件名
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function toDownDocx(data,fileName) {
  const link = document.createElement('a')
  let blob = new Blob([data], {type: 'application/msword'})
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  link.download = fileName //下载的文件名
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export function toDownPdf(data,fileName) {
  const link = document.createElement('a')
  let blob = new Blob([data], {type: 'application/pdf'})
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  link.download = fileName //下载的文件名
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar, subStr) {
  if (!subStr) subStr = 'http'
  if (avatar && avatar.startsWith(subStr)) {
    return avatar
  } else {
    if (avatar && avatar.length > 0 && avatar.indexOf('[') == -1) {
      return window._CONFIG['staticDomainURL'] + '/' + avatar
    }
  }
}
